const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Loader from "./Loader";

Routing.setRoutingData(routes);

export default class Params {

    /**
     * @param choice
     */
    toggleBundleProductTypes(choice) {
        let $bundleProductTypes = $('.bundle-product-types');

        $bundleProductTypes.hide();

        if (+$(choice).val() === 1) {
            $bundleProductTypes.show();
        }
    }

    toogleCaseType(choice) {

        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($('.update-form'));
        let $caseTypes = $('.case-types');
        let $caseTypeSelect = $('.case-type-select');

        let $caseTypeOptions = $caseTypeSelect.find('option');

        $caseTypes.hide();

        let productTypes = $caseTypes.attr('data-product-types');
        productTypes = productTypes.split(',');

        productTypes.map((item) => {
            return parseInt(item);
        });
        if ($caseTypeSelect.length > 0) {
            $.when(
                $.ajax({
                    url: Routing.generate('params_default_descriptions_case_types', {productType: choice}, false),
                    type: 'GET',
                    dataType: 'json',
                })
            ).then((result) => {     
                let html = '<option value="">------</option>';
                let caseTypesId = [];

                for (let c in result) {
                    caseTypesId.push(result[c]['id']);
                }

                $caseTypeOptions.each(function (key, item) {
                    let selectValue = +$(item).val();
                    let selectName = $(item).text();

                    if (caseTypesId.indexOf(selectValue) === -1 && selectValue !== 0) {
                        html += '<option value="' + selectValue + '">' + selectName + '</option>';
                    } else if ( selectValue !== 0) {
                        html += '<option disabled value="' + selectValue + '">' + selectName + '</option>';
                    }
                });

                $caseTypeSelect.html(html);

                for (let i in productTypes) {
                    if (productTypes[i] === choice) {
                        $caseTypes.show();
                    }
                }
                LoaderObject.removeLoader(loaderSpinner);
            });
        } else {
            LoaderObject.removeLoader(loaderSpinner);
        }

    }

    /**
     * @returns {Models}
     * @param model
     * @param $caseTypeOptions
     */
    ajaxCaseTypesDefualtDescriptions(productType, $caseTypeOptions) {

        $.when(
            $.ajax({
                url: Routing.generate('params_default_descriptions_case_types', {productType: productType}, false),
                type: 'GET',
                dataType: 'json',
            })
        ).then((result) => {
            this.showCaseTypes(result, $caseTypeOptions);
        });
        
        return this;
    }

    /**
     * @param choice
     */
    toggleGradesThatHaveOpticalDrive(choice) {
        let $toggleGradesThatHaveOpticalDrive = $('.grades-that-have-optical-drive');

        $toggleGradesThatHaveOpticalDrive.hide();

        if (+$(choice).val() === 1) {
            $toggleGradesThatHaveOpticalDrive.show();
        }
    }

    /**
     * @param series
     */
    showSeries(series) {
        let $seriesSelect = $('.series-select');
        let selectValue = $seriesSelect.val();

        if (series.length > 0) {
            let html = '<option value="">------</option>';

            for (let s in series) {
                html += '<option value="' + series[s].id + '">' + series[s].name + '</option>';
            }

            $seriesSelect.html(html).val(selectValue).closest('.series').show();
        } else {
            let html = '<option value="">------</option>';

            $seriesSelect.html(html).val(selectValue).closest('.series').show();
        }
    }

    /**
     * @param manufacturer
     * @returns {Models}
     */
    ajaxManufacturer(manufacturer) {
        if (manufacturer !== '') {
            $.when(
                $.ajax({
                    url: Routing.generate('model_manufacturer_series', {manufacturer: manufacturer}, false),
                    type: 'GET',
                    dataType: 'json',
                })
            ).then((result) => {
                this.showSeries(result);
            });
        }

        return this;
    }

    /**
     *
     */
    handleEvent() {
        let $manufacturerSelect = $('.manufacturer-select');

        if ($manufacturerSelect.length > 0) {
            $manufacturerSelect.on('change', (event) => {
                let manufacturer = $(event.currentTarget).val();

                this.ajaxManufacturer(manufacturer);
            }).trigger('change');
        }

        let $productTypeSelect = $('.product-type-select');

        if ($productTypeSelect.length > 0) {
            $productTypeSelect.on('change', (event) => {
                let productType = $(event.currentTarget).val();

                this.toogleCaseType(productType);
            }).trigger('change');
        }

        let $canCreateBundleChoice = $('.can-create-bundle-choice').find('input[type="radio"]');

        if ($canCreateBundleChoice.length > 0) {
            $canCreateBundleChoice.on('change', () => {
                let $canCreateBundleChoiceChecked = $('.can-create-bundle-choice').find('input[type="radio"]:checked');
                this.toggleBundleProductTypes($canCreateBundleChoiceChecked);
            }).trigger('change');
        }

        let $hasOpticalDriveChoice = $('.has-optical-drive-choice').find('input[type="radio"]');

        if ($hasOpticalDriveChoice.length > 0) {
            $hasOpticalDriveChoice.on('change', () => {
                let $hasOpticalDriveChoiceChecked = $('.has-optical-drive-choice').find('input[type="radio"]:checked');
                this.toggleGradesThatHaveOpticalDrive($hasOpticalDriveChoiceChecked);
            }).trigger('change');
        }
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}