import Table from "./Table";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Loader from './Loader.js';

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

export default class Models {

    /**
     * @returns {jQuery}
     * @param $list
     */
    createListTable($list) {
        const TableObject = new Table();
        return TableObject.createTable($list, {
            'ajax': Routing.generate('model_list'),
            'columnDefs': [
                {'targets': 0, 'orderable': false},
                {'name': 'model', 'targets': 2},
                {'name': 'pt.name', 'targets': 7},
                {'name': 'mn.name', 'targets': 8},
                {'name': 'ct.name', 'targets': 9},
                {'name': 'photo_update_date', 'targets': 11},
                {'name': 'cover_update_date', 'targets': 12},
            ],
            'columns': [
                {'className': 'action', 'targets': 0},
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
            ],
        });
    }

    /**
     * @param model
     * @param description
     * @param descriptionField
     * @returns {Models}
     */
    ajaxDescriptionPreview(model, description, descriptionField) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader(descriptionField);

        $.when(
            $.ajax({
                url: Routing.generate('model_descriptions_preview', {model: model}, false),
                data: {
                    description: description,
                },
                type: 'POST',
                dataType: 'json',
            })
        ).then((result) => {
            LoaderObject.removeLoader(loaderSpinner);
            $(document).trigger('preview', result);
        });

        return this;
    }

    /**
     * @param caseTypes
     * @param $caseTypeOptions
     */
    showCaseTypes(caseTypes, $caseTypeOptions) {
        let $caseTypeSelect = $('.case-type-select');

        if (caseTypes.length > 0) {
            let html = '<option value="">------</option>';
            let caseTypesId = [];

            for (let c in caseTypes) {
                caseTypesId.push(caseTypes[c]['id']);
            }

            $caseTypeOptions.each(function (key, item) {
                let selectValue = +$(item).val();
                let selectName = $(item).text();

                if (caseTypesId.indexOf(selectValue) === -1) {
                    html += '<option value="' + selectValue + '">' + selectName + '</option>';
                } else {
                    html += '<option disabled value="' + selectValue + '">' + selectName + '</option>';
                }
            });

            $caseTypeSelect.html(html);
        }

        $caseTypeSelect.find('option:first:not(:disabled)').prop('selected', true);
    }

    /**
     * @returns {Models}
     * @param model
     * @param $caseTypeOptions
     */
    ajaxCaseType(model, $caseTypeOptions) {
        if (model !== '') {
            $.when(
                $.ajax({
                    url: Routing.generate('model_name_case_types', {model: model}, false),
                    type: 'GET',
                    dataType: 'json',
                })
            ).then((result) => {
                this.showCaseTypes(result, $caseTypeOptions);
            });
        }

        return this;
    }

    createPreview(value) {
        let $modelDescriptionPreview = $(value).attr('data-preview');

        for (let i = 0; i < tinymce.editors.length; i++) {
            let editor = tinymce.editors[i];
            editor.save();
        }

        let isActive = $(value).hasClass('active');
        let $textareaClass = $($(value).attr('data-preview-textarea'));
        let description = $textareaClass.val();
        let model = +$(value).attr('data-preview-model');

        if (!isActive) {
            this.ajaxDescriptionPreview(model, description, $($modelDescriptionPreview));

            $(document).one('preview', (event, data) => {
                $($modelDescriptionPreview).html(data);
            });
        }
    }

    /**
     * @returns {Models}
     */
    handleEvent() {

        let $modelSelect = $('.model-select');
        let $caseTypeSelect = $('.case-type-select');
        let $caseTypeField = $('.case-type');
        let $caseTypeOptions = $caseTypeSelect.find('option');

        $modelSelect.on('change', (event) => {
            let $selected = $(event.currentTarget).find('option:selected');
            let value = $selected.val();

            this.ajaxCaseType(value, $caseTypeOptions);
            $caseTypeField.show();

        }).trigger('change');

        $('input[type="file"]').on('change', function () {
            let files = [];

            for (let i = 0; i < $(this)[0].files.length; i++) {
                files.push($(this)[0].files[i].name);
            }

            if (files.length > 1) {
                $(this).next('.custom-file-label').html(files.length + ' files');
            } else {
                $(this).next('.custom-file-label').html(files.join(', '));
            }
        });

        let modelDescriptionPreviewHandler = $('[data-preview-handler]');

        modelDescriptionPreviewHandler.on('click', (event) => {
            $(document).trigger('previewInitialize');
            this.createPreview(event.currentTarget);
        });

        let $modelsList = $('.models-list');

        if ($modelsList.length > 0) {
            this.createListTable($modelsList);
        }

        return this;
    }

    /**
     *
     */
    init() {
        this.handleEvent();
    }
}