import Table from "./Table.js";
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Notification from './Notification.js';
import Loader from "./Loader";

const routes = require('../../../public/js/fos_js_routes.json');

Routing.setRoutingData(routes);

require('jquery.scrollto');

export default class Deliveries {


    /**
     * @param $subdeliveryTable
     * @returns {jQuery}
     */
    createSubDeliveryTable($subdeliveryTable) {
        if ($.fn.DataTable.isDataTable($subdeliveryTable)) {
            $subdeliveryTable.DataTable().ajax.reload();
        } else {
            let subDelivery = +$subdeliveryTable.attr('data-subdelivery');

            const TableObject = new Table();
            return TableObject.createTable($subdeliveryTable, {
                'ajax': Routing.generate('deliveries_subdelivery_table', {
                    'subDelivery': subDelivery
                })
            });
        }
    }


    /**
     * @param $deliveryTable
     * @returns {jQuery}
     */
    createDeliveryTable($deliveryTable) {
        if ($.fn.DataTable.isDataTable($deliveryTable)) {
            $deliveryTable.DataTable().ajax.reload();
        } else {
            let delivery = +$deliveryTable.attr('data-delivery');

            const TableObject = new Table();
            return TableObject.createTable($deliveryTable, {
                'ajax': Routing.generate('deliveries_delivery_table', {
                    'delivery': delivery
                })
            });
        }
    }

    /**
     * @param $customSkuStockCountTable
     * @returns {jQuery}
     */
    createCustomSkuStockCountListTable($customSkuStockCountTable) {
        let that = this;
        if ($.fn.DataTable.isDataTable($customSkuStockCountTable)) {
            $customSkuStockCountTable.DataTable().ajax.reload();
        } else {
            let routeUrl = null;

            let delivery = $customSkuStockCountTable.attr("data-delivery");

            if (delivery) {
                routeUrl = Routing.generate('deliveries_delivery_custom_sku_stock_count_table', {
                    'delivery': delivery
                });
            }

            let global = $customSkuStockCountTable.attr("data-global");

            if (global) {
                routeUrl = Routing.generate('deliveries_custom_sku_stock_count_global_table');
            }

            const TableObject = new Table();
            return TableObject.createTable($customSkuStockCountTable, {
                'ajax': routeUrl,
                // orderCellsTop: false,
                "ordering": false,
                // order: [[0, 'asc']],
                drawCallback: function (settings) {
                    // that.initCustomSkuStockCountCreateFormRow();
                    that.initRowFormsEventListeners(this);
                    // that.initShowMore();
                }
            });
        }
    }

    initRowFormsEventListeners($table) {
        let that = this;
        $(".custom-sku-stock-count-table a.rnew-action.update").each(function () {
            $(this).on('click', (event) => {
                event.preventDefault();

                let $rowCustomSkuStockCount = $(event.target.parentElement.parentElement.parentElement);
                // let checkedSku = $('input:checked', $rowForm).val();
                // if (checkedSku) {
                that.saveStockCount($rowCustomSkuStockCount);
                // }
            });
        });

        $(".custom-sku-stock-count-table a.rnew-action.delete").each(function () {
            $(this).on('click', (event) => {
                event.preventDefault();

                let $rowCustomSkuStockCount = $(event.target.parentElement.parentElement.parentElement);
                // let checkedSku = $('input:checked', $rowForm).val();
                // if (checkedSku) {
                that.removeStockCount($rowCustomSkuStockCount, $table);
                // }
            });
        });
        let skuInput = $('input[name="create_cssc_sku"]');
        let countInput = $('input[name="create_cssc_count"]');
        skuInput.focus();

        skuInput.on('keyup', (event) => {
            event.preventDefault();

            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {

                let $rowCustomSkuStockCount = $(event.target.parentElement.parentElement);
                $.when(
                    that.getSkuByExternalNumber($rowCustomSkuStockCount)
                ).then((results) => {
                    countInput.focus();
                });
            }
        });

        countInput.on('keyup', (event) => {
            event.preventDefault();

            let keyCode = event.keyCode || event.which;

            if (keyCode === 13) {
                let $rowCustomSkuStockCount = $(event.target.parentElement.parentElement);
                that.createStockCount($rowCustomSkuStockCount, $table)
                // $inventoryScanCartForm.trigger('submit');
            }
        });

        $(".create-stock-count").on('click', (event) => {
            event.preventDefault();

            let $rowCustomSkuStockCount = $(event.target.parentElement.parentElement.parentElement);
            // let checkedSku = $('input:checked', $rowForm).val();
            // if (checkedSku) {
            that.createStockCount($rowCustomSkuStockCount, $table)
            // }
        });

    }

    /**
     * @returns {*}
     * @param $table
     */
    getSkuByExternalNumber($rowCustomSkuStockCount) {
        let input = $rowCustomSkuStockCount.find('input[name="create_cssc_sku"]');
        let inputValue = input.val();
        let inputData = input.data();
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($rowCustomSkuStockCount);

        return $.ajax({
            type: 'POST',
            url: Routing.generate('sku_validate_by_complete_sku'),
            // contentType: "application/json",
            // processData: false,
            // dataType: 'json',
            data: {
                'sku': inputValue,
                'delivery': inputData.delivery,
            },
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                LoaderObject.removeLoader(loaderSpinner);
                showNotification(response);

                input.parent().next().text(response.skuName);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        });


    }

    initCustomSkuStockCountCreateFormRow() {
        let that = this;
        $(".custom-sku-stock-count-table a.rnew-action.update").each(function () {
            $(this).on('click', (event) => {
                event.preventDefault();

                let $rowCustomSkuStockCount = $(event.target.parentElement.parentElement.parentElement);
                // let checkedSku = $('input:checked', $rowForm).val();
                // if (checkedSku) {
                that.saveStockCount($rowCustomSkuStockCount);
                // }
            });
        });
    }
    /**
     * @returns {*}
     * @param $table
     */
    saveStockCount($rowCustomSkuStockCount) {
        let input = $rowCustomSkuStockCount.find('input');
        let inputData = input.data();
        let newValue = input.val();
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($rowCustomSkuStockCount);

        $.ajax({
            type: 'POST',
            url: Routing.generate('deliveries_custom_sku_stock_count_update'),
            // contentType: "application/json",
            // processData: false,
            // dataType: 'json',
            data: {
                'id': inputData.existingStockCountId,
                'stockCount': newValue,
                'delivery': inputData.delivery,
                'sku': inputData.sku,

            },
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                LoaderObject.removeLoader(loaderSpinner);
                showNotification(response);
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        });


    }

    /**
     * @returns {*}
     * @param $table
     */
    removeStockCount($rowCustomSkuStockCount, $table) {
        let existingStockCountId = $rowCustomSkuStockCount.find('.rnew-action.delete').data('existing-stock-count-id');
        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($rowCustomSkuStockCount);

        $.ajax({
            type: 'POST',
            url: Routing.generate('deliveries_custom_sku_stock_count_remove'),
            // contentType: "application/json",
            // processData: false,
            // dataType: 'json',
            data: {
                'id': existingStockCountId,
            },
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                $.when(
                    $table.DataTable().ajax.reload()
                ).then((results) => {
                    LoaderObject.removeLoader(loaderSpinner);
                    showNotification(response);
                });
            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        });
    }

    /**
     * @returns {*}
     * @param $table
     */
    createStockCount($rowCustomSkuStockCount, $table) {
        let inputSku = $rowCustomSkuStockCount.find('input[name="create_cssc_sku"]');
        let inputSkuValue = inputSku.val();
        let inputSkuData = inputSku.data();

        let inputCount = $rowCustomSkuStockCount.find('input[name="create_cssc_count"]');
        let inputCountValue = inputCount.val();
        let inputCountData = inputCount.data();

        let LoaderObject = new Loader();
        let loaderSpinner = LoaderObject.createLoader($rowCustomSkuStockCount);

        $.ajax({
            type: 'POST',
            url: Routing.generate('deliveries_custom_sku_stock_count_create'),
            // contentType: "application/json",
            // processData: false,
            // dataType: 'json',
            data: {
                // 'id': inputData.existingStockCountId,
                'stockCount': inputCountValue,
                'delivery': inputSkuData.delivery,
                'sku': inputSkuValue,

            },
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                $.when(
                    $table.DataTable().ajax.reload()
                ).then((results) => {
                    LoaderObject.removeLoader(loaderSpinner);
                    showNotification(response);
                });

            },
            error: function (xhr, status, error) {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        });


    }

    /**
     * @param $customizationList
     * @param $removeButton
     * @param $removeAllButton
     */
    addCustomizationRow($customizationList, $removeButton, $removeAllButton) {
        let prototype = $customizationList.data('prototype');
        let index = $customizationList.data('index');

        let newRow = prototype;

        newRow = newRow.replace(/__name__/g, index);

        $customizationList.data('index', index + 1);

        $removeButton.show();
        $removeAllButton.show();

        let $newRowLi = $('<fieldset class="form-group"></fieldset>').append(newRow);
        $customizationList.find('.customization-list-controls').before($newRowLi);
        $('.select2').select2();
    }

    /**
     * @param $customizationList
     * @param $removeButton
     * @param $removeAllButton
     * @param all
     */
    removeCustomizationRow($customizationList, $removeButton, $removeAllButton, all) {
        if (!all) {
            let index = $customizationList.data('index');

            if (index <= 1) {
                return false;
            }

            if (index <= 2) {
                $removeButton.hide();
                $removeAllButton.hide();
            }

            $customizationList.data('index', index - 1);

            let $rowLiLast = $customizationList.find('fieldset.form-group:last');

            $rowLiLast.remove();
        } else {
            $customizationList.data('index', 1);

            let $rowLis = $customizationList.find('fieldset.form-group:not(:first)');

            $rowLis.remove();
            $removeButton.hide();
            $removeAllButton.hide();
        }
    }

    /**
     * @param file
     * @param $customizationList
     * @param $removeButton
     * @param $removeAllButton
     */
    ajaxSendStylesheet(file, $customizationList, $removeButton, $removeAllButton) {
        let formData = new FormData();
        formData.append('file', file[0]);

        $.when($.getJSON({
            type: 'post',
            url: Routing.generate('deliveries_convert_stylesheet'),
            contentType: false,
            processData: false,
            data: formData,
        })).then((response) => {
            this.fillTableFromStylesheet(response, $customizationList, $removeButton, $removeAllButton);
        });
    }

    /**
     * @param stylesheet
     * @param $customizationList
     * @param $removeButton
     * @param $removeAllButton
     */
    fillTableFromStylesheet(stylesheet, $customizationList, $removeButton, $removeAllButton) {
        this.resetTable($customizationList);

        for (let row in stylesheet) {
            if ($customizationList.find('.customization-item').length < stylesheet.length) {
                this.addCustomizationRow($customizationList, $removeButton, $removeAllButton);
            }

            for (let cell in stylesheet[row]) {
                $customizationList.find('.customization-item:eq(' + row + ')')
                    .find('input.form-control:eq(' + cell + ')')
                    .val(stylesheet[row][cell]);
            }
        }

        return this;
    }

    /**
     * @param $customizationList
     */
    resetTable($customizationList) {
        $customizationList.find('.customization-item').each(function () {
            $(this).parent().remove();
        });

        return this;
    }

    /**
     *
     */
    printSummary() {
        window.frames['summary'].focus();
        window.frames['summary'].print();
    }

    /**
     * @param data
     * @param deliveryId
     */
    addFilesForDelivery(data, deliveryId) {
        let LoaderObject = new Loader();

        let $deliveryUploader = $('.deliveries-uploader');
        let loaderSpinner = LoaderObject.createLoader($deliveryUploader);
        let uploaderForm = $('.uploader-form');

        $.getJSON({
            type: 'post',
            url: Routing.generate('deliveries_files_add', { delivery: deliveryId }),
            contentType: false,
            processData: false,
            data: data,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.message);

                uploaderForm.find('.subdelivery').val('');

                $deliveryUploader.jstree('refresh');
            },
            error: (xhr, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();

                if (xhr.status === 404) {
                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                } else {
                    NotificationObject.setErrorAlert(xhr.statusText);
                }

                uploaderForm.find('.subdelivery').val('');
            }
        });
    }

    /**
     * @param href
     * @param $table
     */
    removeProductFromSubdelivery(href, $table) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.subdelivery-wrapper'));

        $.when($.ajax({
            type: 'GET',
            url: href,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);

                if ($table) {
                    $table.DataTable().ajax.reload();
                }
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     * @param href
     * @param $table
     */
    removeProductFromDelivery(href, $table) {
        let LoaderObject = new Loader();

        let loaderSpinner = LoaderObject.createLoader($('.delivery-wrapper'));

        $.when($.ajax({
            type: 'GET',
            url: href,
            success: (response) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setSuccessAlert(response.success);

                if ($table) {
                    $table.DataTable().ajax.reload();
                }
            },
            error: (xhr, status, error) => {
                LoaderObject.removeLoader(loaderSpinner);

                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     * @returns {Models}
     */
    handleEvent() {
        let that = this;
        let $customizationList = $('ul.customization-list');
        let $addRowButton = $('.customization-list-control .add');
        let $removeButton = $('.customization-list-control .remove');
        let $removeAllButton = $('.customization-list-control .remove-all');

        let $index = $customizationList.find('div.customization-item').length;
        $customizationList.data('index', $index);
        if ($index > 1) {
            $removeButton.show();
            $removeAllButton.show();
        };

        $addRowButton.on('click', (event) => {
            event.preventDefault();

            this.addCustomizationRow($customizationList, $removeButton, $removeAllButton);
        });

        $removeButton.on('click', (event) => {
            event.preventDefault();

            this.removeCustomizationRow($customizationList, $removeButton, $removeAllButton, false);
        });

        $removeAllButton.on('click', (event) => {
            event.preventDefault();

            this.removeCustomizationRow($customizationList, $removeButton, $removeAllButton, true);
        });

        console.info('[RNEW v.0 API] Initializing stylesheet drag end drop events');

        let $uploaderDropAndDragSection = $('.deliveries-uploader-drop-section');

        $uploaderDropAndDragSection.on('drag dragstart dragend dragover dragenter dragleave drop', (event) => {
            event.preventDefault();

        }).on('drag dragenter', (event) => {
            console.info('[RNEW v.0 API] Drag start/enter/drag event');
        })
            .on('dragleave dragend drop', (event) => {
                console.info('[RNEW v.0 API] Drag dragleave/dragend/drop/dragend event');
            })
            .on('drop', (event) => {
                let dt = event.dataTransfer || (event.originalEvent && event.originalEvent.dataTransfer);
                let files = event.target.files || (dt && dt.files);

                let deliveryId = +$(event.currentTarget).data('uploader-delivery');
                let subDeliveryId = +$(event.currentTarget).data('uploader-subdelivery');

                let formData = new FormData();
                formData.append('file', files[0]);

                if (subDeliveryId !== '') {
                    formData.append('subdelivery', subDeliveryId);
                }

                this.addFilesForDelivery(formData, deliveryId);
            });

        console.info('[RNEW v.0 API] Initializing deliveries uploader drag end drop events');

        $customizationList
          .on(
            "drag dragstart dragend dragover dragenter dragleave drop",
            (event) => {
            event.preventDefault();
            }
          )
          .on("drag dragenter", (event) => {
            console.info("[RNEW v.0 API] Drag start/enter/drag event");
        })
          .on("dragleave dragend drop", (event) => {
            console.info(
              "[RNEW v.0 API] Drag dragleave/dragend/drop/dragend event"
            );
            })
          .on("drop", (event) => {
            let dt =
              event.dataTransfer ||
              (event.originalEvent && event.originalEvent.dataTransfer);
                let files = event.target.files || (dt && dt.files);

            this.ajaxSendStylesheet(
              files,
              $customizationList,
              $removeButton,
              $removeAllButton
            );
            });

        $('.delivery.printBtn').on('click', (event) => {
            event.preventDefault();

            this.printSummary();
        });

        $('.uploader-form').on('submit', (event) => {
            event.preventDefault();

            let deliveryId = +$(event.currentTarget).parent()
                .data('uploader-delivery');

            let formData = new FormData();
            formData.append('file', $(event.currentTarget).find('input[type=file]')[0].files[0]);
            formData.append('subdelivery', $(event.currentTarget).find('.subdelivery').val());

            this.addFilesForDelivery(formData, deliveryId);
        });

        let $subdeliveryDropdown = $('.subdelivery-dropdown');

        $subdeliveryDropdown.on('click', (event) => {
            event.preventDefault();

            let $subdeliveryTableWrapper = $(event.currentTarget).next().next('.subdelivery-wrapper')
            let $subdeliveryTable = $subdeliveryTableWrapper.find('.subdelivery-table');

            if (!$(event.currentTarget).hasClass('active')) {
                $(event.currentTarget).addClass('active');

                if ($subdeliveryTable.length > 0) {
                    this.createSubDeliveryTable($subdeliveryTable);
                }

                $subdeliveryTable.off('click').on('click', '.delete', (event) => {
                    event.preventDefault();

                    let href = $(event.currentTarget).attr('href');

                    this.removeProductFromSubdelivery(href, $subdeliveryTable);
                });

                $subdeliveryTableWrapper.show(0);

            } else {
                $(event.currentTarget).removeClass('active');
                $subdeliveryTableWrapper.hide(0);
            }
        });

        let $deliveryDropdown = $('.delivery-dropdown');

        $deliveryDropdown.on('click', (event) => {
            event.preventDefault();

            let $deliveryTableWrapper = $(event.currentTarget).next('.delivery-wrapper');
            let $deliveryTable = $deliveryTableWrapper.find('.delivery-table');

            if (!$(event.currentTarget).hasClass('active')) {
                $(event.currentTarget).addClass('active');

                if ($deliveryTable.length > 0) {
                    let $deliveryDataTable = this.createDeliveryTable($deliveryTable);
                }

                $deliveryTable.off('click').on('click', '.delete', (event) => {
                    event.preventDefault();

                    let href = $(event.currentTarget).attr('href');

                    this.removeProductFromDelivery(href, $deliveryTable);
                });

                $deliveryTableWrapper.show(0);
            } else {
                $(event.currentTarget).removeClass('active');
                $deliveryTableWrapper.hide(0);
            }
        });

        let $summaryDropdown = $('.summary-dropdown');

        $summaryDropdown.on('click', (event) => {
            event.preventDefault();

            let $summaryWrapper = $('.summary-wrapper');

            if (!$(event.currentTarget).hasClass('active')) {
                $(event.currentTarget).addClass('active');

                $summaryWrapper.show(0);
            } else {
                $(event.currentTarget).removeClass('active');

                $summaryWrapper.hide(0);
            }
        });


        // let $globalCustomSkuStockCountWrapper = $('.global-custom-sku-stock-count-wrapper');

        // if ($globalCustomSkuStockCountWrapper.length > 0) {
        let $globalCustomSkuStockCountList = $('.global-custom-sku-stock-count-table');

        if ($globalCustomSkuStockCountList.length > 0) {
            this.createCustomSkuStockCountListTable($globalCustomSkuStockCountList);
        }
        // }

        let $customSkuStockCountDropdown = $('.custom-sku-stock-count-dropdown');

        $customSkuStockCountDropdown.on('click', (event) => {
            event.preventDefault();

            let $customSkuStockCountWrapper = $('.custom-sku-stock-count-wrapper');
            let $customSkuStockCountList = $customSkuStockCountWrapper.find('.custom-sku-stock-count-table');

            if (!$(event.currentTarget).hasClass('active')) {
                $(event.currentTarget).addClass('active');

                if ($customSkuStockCountList.length > 0) {
                    let $customSkuStockCountTable = this.createCustomSkuStockCountListTable($customSkuStockCountList);
                }


                $customSkuStockCountWrapper.show(0);
            } else {
                $(event.currentTarget).removeClass('active');

                $customSkuStockCountWrapper.hide(0);
            }
        });
        setTimeout(() => {
            let $deliveryTab = $('.delivery-tab');

            if ($deliveryTab.length > 0) {
                let hash = location.hash;

                if ($(hash).length > 0) {
                    $('body').scrollTo(hash, 200);

                    let $subdeliveryDropdown = $(hash);
                    $subdeliveryDropdown.trigger('click');
                }
            }
        }, 500);

        $('#autopricing_button').on('click', (event) => {
            event.preventDefault();

            let LoaderObject = new Loader();

            let $contentWrapper = $('.content-wrapper');
            LoaderObject.createLoader($contentWrapper);

            $.getJSON({
                type: 'post',
                url: Routing.generate('deliveries_subdelivery_autopricing', { delivery: deliveryId }),
                contentType: false,
                processData: false,
                data: data,
                success: (response) => {
                    LoaderObject.removeLoader(loaderSpinner);

                    const NotificationObject = new Notification();
                    NotificationObject.setSuccessAlert(response.message);

                    uploaderForm.find('.subdelivery').val('');

                    $deliveryUploader.jstree('refresh');
                },
                error: (xhr, error) => {
                    LoaderObject.removeLoader(loaderSpinner);

                    const NotificationObject = new Notification();

                    if (xhr.status === 404) {
                        NotificationObject.setErrorAlert(xhr.responseJSON.error);
                    } else {
                        NotificationObject.setErrorAlert(xhr.statusText);
                    }
                }
            });
        });

        // $('.subdeliveries-dates-report a.rnew-action.update-comment').on('click', (event) => {
        //     event.preventDefault();
        //     this.initCommentEvent($(event.currentTarget).parent());
        // });

        // $('.subdeliveries-dates-report a.rnew-action.update-comment').on('click', this.initCommentEvent.bind(this,this));

        $('.subdeliveries-dates-report a.rnew-action.update-comment').each(function (index, element) {
            that.initCommentEvent(element);
        });

        return this;
    }

    /**
     * @returns {Deliveries}
     */
    prepareTreeView() {
        $('.deliveries-tree').jstree(
            {
                'core': {
                    'themes': {
                        'name': 'proton',
                    },
                },
                'types': {
                    'default': {
                        'icon': 'fas fa-truck',
                    },
                },
                'plugins': [
                    'types',
                    'contextmenu',
                ],
                'contextmenu': {
                    'select_node': false,
                    'items': ($node) => {
                        let $deliveryListTree = $('.deliveries-tree');
                        let translations = $deliveryListTree.data('delivery-translations');

                        return {
                            'openInNewTab': {
                                'separator_before': false,
                                'separator_after': false,

                                'label': translations.openInNewTab,
                                'action': function (obj) {
                                    window.open($node.a_attr.href, '_blank');
                                }
                            },
                            'copy': {
                                'separator_before': false,
                                'separator_after': false,
                                'label': translations.copy,
                                'action': function (obj) {
                                    let $copyArea = document.getElementById('copy-section');

                                    $copyArea.value = $node.text;
                                    $copyArea.select();
                                    $copyArea.setSelectionRange(0, 99999);

                                    document.execCommand('copy');
                                }
                            },
                            'copyAsLink': {
                                'separator_before': false,
                                'separator_after': false,

                                'label': translations.copyAsLink,
                                'action': function (obj) {
                                    let $copyArea = document.getElementById('copy-section');
                                    $copyArea.value = '<a href="' + $node.a_attr.href + '">' + $node.text + '</a>';
                                    $copyArea.select();
                                    $copyArea.setSelectionRange(0, 99999);

                                    document.execCommand('copy');
                                }
                            }
                        };
                    }
                }
            }
        ).bind('select_node.jstree', function (event, data) {
            document.location.href = data.node.a_attr.href;
        });

        return this;
    }

    /**
     * @returns {Deliveries}
     */
    prepareUploader() {
        let $deliveryUploader = $('.deliveries-uploader');
        let deliveryId = +$deliveryUploader.data('uploader-delivery');
        let $deliveryUploaderForm = $('.deliveries-uploader-form');

        $deliveryUploader
            .jstree(
                {
                    'core': {
                        'themes': {
                            'responsive': true
                        },
                        'data': {
                            type: 'post',
                            url: Routing.generate('deliveries_files_list', { delivery: deliveryId }),
                            contentType: 'application/json; charset=utf-8',
                            success: function (data) {
                                data.d;
                                $(data).each(function () {
                                    return { 'id': this.id, 'type': this.type };
                                });
                            }
                        },
                    },
                    'types': {
                        'default': {
                            'icon': 'fas fa-file-alt',
                        },
                        'dir': {
                            'icon': 'fas fa-folder',
                        },
                        'doc': {
                            'icon': 'fas fa-file-word',
                        },
                        'docx': {
                            'icon': 'fas fa-file-word',
                        },
                        'jpg': {
                            'icon': 'fas fa-file-image',
                        },
                        'png': {
                            'icon': 'fas fa-file-image',
                        },
                        'gif': {
                            'icon': 'fas fa-file-image',
                        },
                        'ptt': {
                            'icon': 'fas fa-file-powerpoint',
                        },
                        'pttx': {
                            'icon': 'fas fa-file-powerpoint',
                        },
                        'xls': {
                            'icon': 'fas fa-file-excel',
                        },
                        'xlsx': {
                            'icon': 'fas fa-file-excel',
                        },
                        'mp4': {
                            'icon': 'fas fa-file-excel',
                        },
                        'wav': {
                            'icon': 'fas fa-file-excel',
                        },
                        'mp3': {
                            'icon': 'fas fa-file-audio',
                        },
                        'pdf': {
                            'icon': 'fas fa-file-pdf',
                        }
                    },
                    'plugins': [
                        'types',
                        'contextmenu',
                    ],
                    'contextmenu': {
                        'items': ($node) => {
                            let translations = $deliveryUploader.data('uploader-translations');

                            return {
                                'download': {
                                    'separator_before': false,
                                    'separator_after': false,
                                    'label': translations.download,
                                    'action': function (obj) {
                                        window.open($node.a_attr.href, '__blank');
                                    }
                                },
                                'remove': {
                                    'separator_before': false,
                                    'separator_after': false,
                                    'label': translations.remove,
                                    'action': function (obj) {
                                        let LoaderObject = new Loader();

                                        let $deliveryUploader = $('.deliveries-uploader');
                                        let loaderSpinner = LoaderObject.createLoader($deliveryUploader);

                                        $.getJSON({
                                            type: 'post',
                                            url: Routing.generate('deliveries_files_remove', { delivery: deliveryId }),
                                            data: {
                                                file: $node.original.path,
                                            },
                                            success: function (response) {
                                                const NotificationObject = new Notification();
                                                NotificationObject.setSuccessAlert(response.message);

                                                $deliveryUploader.jstree('refresh');
                                            },
                                            error: (xhr, error) => {
                                                LoaderObject.removeLoader(loaderSpinner);

                                                console.log(error);

                                                const NotificationObject = new Notification();

                                                if (xhr.status === 404) {
                                                    NotificationObject.setErrorAlert(xhr.responseJSON.error);
                                                } else {
                                                    NotificationObject.setErrorAlert(xhr.statusText);
                                                }
                                            }
                                        })
                                    }
                                },
                            };
                        }
                    }
                }
            ).bind('select_node.jstree', function (event, data) {
                event.preventDefault();

                let type = data.node.original.type;
                let id = data.node.original.id;

                if (type === 'dir') {
                    $deliveryUploaderForm.find('.uploader-form').find('.subdelivery').val(id);
                    let $uploaderDropAndDragSection = $('.deliveries-uploader-drop-section');
                    $uploaderDropAndDragSection.attr('data-uploader-subdelivery', id);
                }
            });

        let $deliveryAddButton = $('.delivery-basket-add-btn');

        $deliveryAddButton.on('click', (event) => {
            event.preventDefault();
            let basketNumber = $(".choose-basket-select option:selected").val();

            let delivery = $(event.currentTarget).data('delivery');

            this.addProductsFromDeliveryToBasket(basketNumber, delivery);
        });

        return this;
    }

    initCommentEvent(cell) {
        let that = this;
        $(cell).on('click', (event) => {
            event.preventDefault();


            let LoaderObject = new Loader();
            let thisCell = $(event.currentTarget).parent();
            // let thisCell = cell;
            let loaderSpinner = LoaderObject.createLoader(thisCell);

            let sdId = thisCell.attr('data-sd-id');
            let commValue = thisCell.text().trim();
            let inputText = `<input type="text" data-sd-id="${sdId}" name="sd-comm" required="required" class="form-control" value="${commValue}">
            <a title="{{ 'accessories.list.action.update'|trans }}" class="rnew-action save-comment" href="javascript:void(0);"><i class="fas fa-save"></i></a>
            `;

            thisCell.html(inputText);
            LoaderObject.removeLoader(loaderSpinner);

            thisCell.find('.save-comment').on('click', (event) => {
                let LoaderObject = new Loader();
                let thisCell = $(event.currentTarget).parent();
                let loaderSpinner = LoaderObject.createLoader(thisCell);

                let commentValue = thisCell.find('input[name="sd-comm"]').val().trim();
                // return;
                $.ajax({
                    type: 'POST',
                    url: Routing.generate('deliveries_subdelivery_comment_update'),
                    // contentType: "application/json",
                    // processData: false,
                    // dataType: 'json',
                    data: { 'sdId': sdId, 'commentValue': commentValue },
                    success: function (response) {

                        if (response.success) {
                            let cellContent = `${commentValue} <a title="{{ 'accessories.list.action.update'|trans }}" class="rnew-action update-comment"><i class="fas fa-edit"></i></a>`;

                            thisCell.html(cellContent);
                            that.initCommentEvent(thisCell.find('a.rnew-action.update-comment'));
                        }
                        function showNotification(element) {
                            const NotificationObject = new Notification();
                            if (element.success) {
                                NotificationObject.setSuccessAlert(element.success);
                            } else if (element.info) {
                                NotificationObject.setInfoAlert(element.info);
                            } else if (element.error) {
                                NotificationObject.setErrorAlert(element.error);
                            }
                        }
                        LoaderObject.removeLoader(loaderSpinner);
                        showNotification(response);
                    },
                    error: function (xhr, status, error) {
                        LoaderObject.removeLoader(loaderSpinner);

                        const NotificationObject = new Notification();
                        NotificationObject.setErrorAlert(xhr.responseJSON.error);
                    }
                });
            });
        });
    }
    /**
     * @returns {*}
     * @param basketNumber
     * @param delivery
     */
    addProductsFromDeliveryToBasket(basketNumber, delivery) {
        return $.when($.ajax({
            type: 'POST',
            url: Routing.generate(
                'deliveries_delivery_add_product_to_basket',
                { delivery: delivery, basket: basketNumber }
            ),
            contentType: false,
            processData: false,
            success: function (response) {
                function showNotification(element) {
                    const NotificationObject = new Notification();
                    if (element.success) {
                        NotificationObject.setSuccessAlert(element.success);
                    } else if (element.info) {
                        NotificationObject.setInfoAlert(element.info);
                    } else if (element.error) {
                        NotificationObject.setErrorAlert(element.error);
                    }
                }

                response.forEach(showNotification);
            },
            error: function (xhr, status, error) {
                const NotificationObject = new Notification();
                NotificationObject.setErrorAlert(xhr.responseJSON.error);
            }
        }))
    }

    /**
     *
     */
    init() {
        this.prepareTreeView()
            .prepareUploader()
            .handleEvent()
            ;
    }
}