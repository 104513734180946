import Notification from './modules/Notification.js';
import ProfileSlider from './modules/ProfileSlider.js';
import Modal from './modules/Modal.js';
import Models from './modules/Models.js';
import Products from './modules/Products.js';
import TinyMCE from './modules/TinyMCE.js';
import AllegroEditor from './modules/AllegroEditor.js';
import DropzoneHelper from './modules/DropzoneHelper.js';
import Deliveries from './modules/Deliveries.js';
import Refurbish from './modules/Refurbish.js';
import SerialNumberEditor from './modules/SerialNumberEditor.js';
import Params from './modules/Params.js';
import Sku from './modules/Sku.js';
import Basket from './modules/Basket.js';
import Warehouse from './modules/Warehouse.js';
import Shelves from './modules/Shelves.js';
import Aisles from './modules/Aisles.js';
import Carts from './modules/Carts.js';
import Table from './modules/Table.js';
import Inventory from './modules/Inventory.js';
import Permissions from "./modules/Permissions.js";
import Batteries from "./modules/Batteries.js";

class Main {

    /**
     * @returns {Main}
     */
    createNotification() {
        const $notificationBlock = $('.notification');

        $notificationBlock.each(function (index) {

            let notificationValue = $(this).html();
            let notificationCloseSignValue = $(this).data('close-sign');

            let NotificationObject = new Notification();

            if ($(this).hasClass('error')) {
                NotificationObject.setErrorAlert(notificationValue, notificationCloseSignValue);
            }

            if ($(this).hasClass('success')) {
                NotificationObject.setSuccessAlert(notificationValue, notificationCloseSignValue);
            }

            if ($(this).hasClass('info')) {
                NotificationObject.setInfoAlert(notificationValue, notificationCloseSignValue);
            }

            if ($(this).hasClass('warning')) {
                NotificationObject.setWarningAlert(notificationValue, notificationCloseSignValue);
            }
        });
        console.info('[RNEW v.0 API] Notifications initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initProfileSlider() {
        const ProfileSliderObject = new ProfileSlider();
        ProfileSliderObject.init();

        console.info('[RNEW v.0 API] Profile slider initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initTables() {
        let $dataTable = $('.dataTable');

        $dataTable.each(function() {
            let TableObject = new Table();
            TableObject.createTable($(this));
        });

        console.info('[RNEW v.0 API] Datatables initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initModals() {
        const ModalObject = new Modal();
        ModalObject.init();

        console.info('[RNEW v.0 API] Modals initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initSystemModules() {
        const ModelsObject = new Models();

        ModelsObject.init();

        console.info('[RNEW v.0 API] System models initialized');

        const ProductsObject = new Products();

        ProductsObject.init();

        console.info('[RNEW v.0 API] System products initialized');

        const DeliveriesObject = new Deliveries();

        DeliveriesObject.init();

        console.info('[RNEW v.0 API] System deliveries initialized');

        const RefurbishObject = new Refurbish();

        RefurbishObject.init();

        console.info('[RNEW v.0 API] System refurbish initialized');

        const SerialNumberEditorObject = new SerialNumberEditor();

        SerialNumberEditorObject.init();

        console.info('[RNEW v.0 API] System SerialNumberEditor initialized');

        const ParamsObject = new Params();

        ParamsObject.init();

        console.info('[RNEW v.0 API] System params initialized');

        const SkuObject = new Sku();

        SkuObject.init();

        console.info('[RNEW v.0 API] System SKU initialized');

        const BasketObject = new Basket();

        BasketObject.init();

        console.info('[RNEW v.0 API] System basket initialized');

        const WarehouseObject = new Warehouse();

        WarehouseObject.init();

        console.info('[RNEW v.0 API] System warehouse initialized');

        const ShelvesObject = new Shelves();

        ShelvesObject.init();

        console.info('[RNEW v.0 API] System shelves initialized');

        const AislesObject = new Aisles();

        AislesObject.init();

        console.info('[RNEW v.0 API] System aisles initialized');

        const CartsObject = new Carts();

        CartsObject.init();

        console.info('[RNEW v.0 API] System carts initialized');

        const InventoryObject = new Inventory();

        InventoryObject.init();

        console.info('[RNEW v.0 API] System inventory initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initPermissions() {
        const PermissionsObject = new Permissions();

        PermissionsObject.init();

        console.info('[RNEW v.0 API] System permissions initialized');

        return this;
    }
    /**
     * @returns {Main}
     */
    initBatteries() {
        const BatteriesObject = new Batteries();

        BatteriesObject.init();

        console.info('[RNEW v.0 API] Batteries initialized');

        return this;
    }
    /**
     * @returns {Main}
     */
    initTinyMCE() {
        const TinyMCEObject = new TinyMCE();
        TinyMCEObject.init();

        if ($('#update_form_allegroDescription').length > 0) {
            const AuctionEditorObject = new AllegroEditor('#update_form_allegroDescription');
            AuctionEditorObject.init();
        }

        console.info('[RNEW v.0 API] TinyMCE initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initDropzone() {
        const DropzoneObject = new DropzoneHelper();

        DropzoneObject.init();

        console.info('[RNEW v.0 API] Drag and drop initialized');

        return this;
    }

    /**
     * @returns {Main}
     */
    initSockets() {
        // let socket = io('http://0.0.0.0:3010');

        console.info('[RNEW v.0 API] Socket IO initialized');

        return this;
    }
}

$(document).ready(() => {
    const MainObject = new Main();

    MainObject
        .initSockets()
        .createNotification()
        .initProfileSlider()
        .initTables()
        .initDropzone()
        .initModals()
        .initSystemModules()
        .initTinyMCE()
        .initPermissions()
        .initBatteries()
    ;

    $('[data-toggle="tooltip"]').tooltip();
    $('.select2').select2();

    window.scrollTo(0, 0);

    if (window.history.replaceState) {
        window.history.replaceState(null, null, window.location.href);
    }

    $('input[name="datefilter"]').daterangepicker({
        locale: {
            format: 'DD-MM-YYYY'
        }
    });
});
