const routes = require('../../../public/js/fos_js_routes.json');
import Routing from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
import Loader from '../modules/Loader.js';

Routing.setRoutingData(routes);

export default class AllegroEditor {

    /**
     * @param target
     */
    constructor(target) {
        let self = this;
        this.instance = 'auction-editor-' + (new Date().getTime());
        this.tinymceConfig = {
            selector: '#' + this.instance + ' li.active textarea.tinymce',
            mode: 'textareas',
            menubar: '',
            content_css: '//www.tiny.cloud/css/codepen.min.css',
            skins: false,
            height: 500,
            branding: false,
            browser_spellcheck: true,
            verify_html: true,
            statusbar: false,
            paste_auto_cleanup_on_paste: true,
            paste_convert_headers_to_strong: false,
            paste_strip_class_attributes: "all",
            paste_remove_spans: true,
            paste_remove_styles: true,
            remove_linebreaks: false,
            convert_urls: true,
            formats: {
                bold: {inline: 'b', deep: true, split: true},
            },
            indent_before: 'p,h1,h2,h3,h4,h5,h6,blockquote,div,title,style,pre,script,td,ul,ol,li,dl,dt,dd,area,table,thead,tfoot,tbody,tr,section,article,hgroup,aside,figure,option,optgroup,datalist',
            indent_after: 'p,h1,h2,h3,h4,h5,h6,blockquote,div,title,style,pre,script,td,ul,ol,li,dl,dt,dd,area,table,thead,tfoot,tbody,tr,section,article,hgroup,aside,figure,option,optgroup,datalist',
            forced_root_block: 'p',
            force_p_newlines: true,
            gecko_spellcheck: true,
            style_formats: [
                {block: 'p', deep: true, name: 'custom0', split: true, title: 'Paragraf'},
                {block: 'h1', deep: true, name: 'custom1', split: true, title: 'Nagłówek 1'},
                {block: 'h2', deep: true, name: 'custom2', split: true, title: 'Nagłówek 2'},
            ],
            theme: 'silver',
            entity_encoding: 'raw',
            valid_elements: 'h1,h2,b,ol,ul,li,p',
            valid_children: '-h1[b],-h2[b],-li[h1|h2]',
            block_formats: 'Paragraf=p;Nagłowek 1=h1;Nagłowek 2=h2;',
            toolbar: 'undo redo | bbcodesSection | formatselect | numlist bullist | bold | code | fullscreen',
            plugins: 'visualblocks, lists, autosave, autoresize, code',
            visualblocks_default_state: true,
            end_container_on_empty_block: true,
            setup: function (editor) {
                let $tinyMceCustom = $('#' + self.instance);
                let systemVariablesEnabled = $tinyMceCustom.attr('data-tinymce-system-variables') || false;

                if (systemVariablesEnabled) {
                    editor.ui.registry.addMenuButton('bbcodesSection', {
                        text: 'MARS ZMIENNE (BBCODE)',
                        fetch: function (callback) {
                            let items = [
                                {
                                    type: 'menuitem',
                                    text: 'Nazwa modelu ([mars:model:name:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:name:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Nazwa SKU ([mars:sku:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Nazwa SKU (skrócona) ([mars:sku:shortname])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:shortname]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Procesor (CPU)',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:cpu:name:name] [mars:sku:cpu:speed:name] [mars:sku:cpu:speedturbo:name] [mars:sku:cpu:cores:name] [mars:sku:cpu:cache:name] [mars:sku:cpu:socket:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Dysk twardy (HDD)',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:hddcapacity:name] [mars:sku:hddcapacity:hddtype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Kondycja ([mars:sku:grade:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:grade:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Opis kondycji ([mars:sku:grade:description])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:grade:description]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Seria ([mars:model:series:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:series:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Producent ([mars:model:manufacturer:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:manufacturer:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Lista Interfejsów ([mars:model:interfaces:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:interfaces:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Format wyświetlacza ([mars:model:displayformat:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:displayformat:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ wyświetlacza ([mars:model:screentype:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:screentype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Powłoka wyświetlacza ([mars:model:displayshell:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:displayshell:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Rozdzielczość ekranu ([mars:model:screenresolution:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:screenresolution:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Przekątna ekranu ([mars:model:screensize:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:screensize:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'CDROM ([mars:model:hasopticaldrive])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:hasopticaldrive]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Ekran dotykowy ([mars:model:touchscreen:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:touchscreen:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Kamera ([mars:sku:camera:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:camera:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Opis kamery ([mars:sku:camera:description])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:camera:description]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Podświetlenie ([mars:model:backlight:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:backlight:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Pojemność baterii ([mars:model:batterycapacity:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:batterycapacity:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Licencja ([mars:sku:license:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:license:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Opis licencji ([mars:sku:license:description])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:license:description]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'System operacyjny ([mars:sku:license:operatingsystem:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:license:operatingsystem:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ laptopa (Allegro) ([mars:model:allegrolaptoptype:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegrolaptoptype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ komputera (Allegro) ([mars:model:allegropctype:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegropctype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ obudowy ([mars:model:casetype:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:casetype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Kolor obudowy ([mars:model:casecolor:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:casecolor:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Materiał (Allegro) ([mars:model:allegromaterial:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegromaterial:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Szerokość ([mars:model:width])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:width]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Wysokość ([mars:model:height])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:height]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Długość ([mars:model:length])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:length]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Waga ([mars:model:weight])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:weight]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Maksymalna pamięć RAM ([mars:model:rammaxsize:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:rammaxsize:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ RAM ([mars:model:ramtype:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:ramtype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Ilość slotów RAM ([mars:model:ramslots:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:ramslots:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Pamięć RAM ([mars:sku:ramcapacity:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:sku:ramcapacity:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'List interfejsów dysku twardego ([mars:model:hddinterface:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:hddinterface:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Komunikacja (Allegro) ([mars:model:allegrocommunication:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegrocommunication:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Sterowanie (Allegro) ([mars:model:allegrocontrols:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegrocontrols:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Multimedia (Allegro) ([mars:model:allegromultimedia:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegromultimedia:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ karty graficznej (GPU) ([mars:model:allegrogpu:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:allegrogpu:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Typ podświetlenia ([mars:model:backlighttype:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:backlighttype:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Częstotliwość odświeżania obrazu ([mars:model:displayrefreshrate:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:displayrefreshrate:name]');
                                    }
                                },
                                {
                                    type: 'menuitem',
                                    text: 'Kontrast obrazu ([mars:model:screencontrast:name])',
                                    onAction: function (_) {
                                        editor.insertContent('[mars:model:screencontrast:name]');
                                    }
                                },
                            ];

                            callback(items);
                        }
                    });
                }
            }
        }
        this.status = 'ready';
        this.target = $(target);
        this.selectorId = target;
        this.initFn = function () {
        };
        this.saveFn = function () {
        };
        this.destroyFn = function () {
        };
        this.texts();
        this.images();
        this.sectionBar();
        //this.addPhotoModal();
    }

    /**
     * @returns {*}
     */
    getSelectorId() {
        return this.selectorId;
    }

    /**
     * @returns {boolean}
     */
    init() {
        if (this.status === 'initialized') {
            return false;
        }

        this.addLayout();
        this.events();
        this.target.hide();

        let that = this;

        $('#' + this.instance + ' ul').sortable({
            handle: '.sort-handle',
            start: (e, ui) => {
                that.removeTinymce();
            },
            stop: (e, ui) => {
                that.initTinymce();
                that.save();
            }
        });

        this.status = 'initialized';
        this.initFn(this.target);
    }

    /**
     * @param triger
     * @param fn
     */
    setCallBack(triger, fn) {
        if (fn && fn.constructor === Function) {
            switch (triger) {
                case 'init':
                    this.initFn = fn;
                    break;
                case 'save':
                    this.saveFn = fn;
                    break;
                case 'destroy':
                    this.destroyFn = fn;
                    break;
            }
        }
    }

    /**
     *
     */
    texts() {
        this.texts = {
            'onlyText': 'Tylko tekst',
            'onlyPhoto': 'Tylko zdjęcie',
            'photoAndText': 'Zdjęcie i tekst',
            'textAndPhoto': 'Tekst i zdjęcie',
            'twoPhotos': 'Dwa zdjęcia',
            'move': 'Przenieś',
            'remove': 'Usuń',
            'addText': 'Dodaj jakiś tekst',
            'addSection': 'Dodaj kolejną sekcję',
            'choosePhoto': 'Wybierz obrazek'
        }
    }

    images() {
        this.images = {
            'default': '',
        }
    }

    /**
     * @returns {string}
     */
    getStatus() {
        return this.status;
    }

    /**
     *
     */
    sectionBar() {
        this.section_bar =
            `<div class='section-bar'>
                <div class='section-bar-left'>
                    <a href="#text" title="${this.texts.onlyText}">
                        <i class="fas fa-align-justify"></i>
                    </a>
                    <a href="#photo" title="${this.texts.onlyPhoto}">
                        <i class="far fa-images"></i>
                    </a>
                    <a href="#photo-and-text" title="${this.texts.photoAndText}">
                        <i class="far fa-images"></i>
                        <i class="fas fa-align-justify"></i>
                    </a>
                    <a href="#text-and-photo" title="${this.texts.textAndPhoto}">
                        <i class="fas fa-align-justify"></i>
                        <i class="far fa-images"></i>
                    </a>
                    <a href="#double-photo" title="${this.texts.twoPhotos}">
                        <i class="far fa-images"></i>
                        <i class="far fa-images"></i>
                    </a>
                </div>

                <div class="section-bar-right">
                    <a href="#" class="sort-handle" title="${this.texts.move}">
                        <i class="fas fa-arrows-alt"></i>
                    </a>
                    <a href="#delete" title="${this.texts.remove}">
                        <i class="fas fa-trash"></i>
                    </a>
                </div>
            </div>`;

    }

    /**
     * @param text
     * @returns {string}
     */
    addSectionText(text) {
        text = text ? text : this.texts.addText;

        return `
                <div class="auction-section auction-text clearfix" data-type="text">
                    ${this.section_bar}
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="auction-preview">${text}</div>
                            <textarea data-tinymce-system-variables="true" name="text" class="tinymce">${text}</textarea>
                        </div>
                    </div>
                </div>`;
    }

    /**
     * @param src
     * @returns {string}
     */
    addSectionPhoto(src) {
        src = src ? src : this.images.default;

        let filledClass = '';

        if (src !== this.images.default) {
            filledClass = 'filled';
        }

        return `
                <div class="auction-section auction-photo clearfix" data-type="photo">
                    ${this.section_bar}
                    <div class="row">
                        <div class="col-lg-12 photo-section-wrapper ${filledClass}" style="cursor: pointer" data-toggle="modal">
                            <div class="photo-section"></div>
                            <img src="${src}">
                            <input name="photo" type="hidden" value="${src}" />
                        </div>
                    </div>
                </div>`;
    }

    /**
     * @param firstSrc
     * @param secondSrc
     * @returns {string}
     */
    addSectionDoublePhoto(firstSrc, secondSrc) {
        firstSrc = firstSrc ? firstSrc : this.images.default;
        secondSrc = secondSrc ? secondSrc : this.images.default;

        let filledClassFirst = '';
        let filledClassSecond = '';

        if (firstSrc !== this.images.default) {
            filledClassFirst = 'filled';
        }

        if (secondSrc !== this.images.default) {
            filledClassSecond = 'filled';
        }

        return `
                <div class="auction-section auction-double-photo clearfix" data-type="double-photo">
                    ${this.section_bar}
                    <div class="row">
                        <div class="col-lg-6 first-src photo-section-wrapper ${filledClassFirst}" style="cursor: pointer" data-toggle="modal">
                            <div class="photo-section"></div>
                             <img src="${firstSrc}">
                             <input name="photo-first" type="hidden" value="${firstSrc}" />
                        </div>
                        <div class="col-lg-6 second-src photo-section-wrapper ${filledClassSecond}" style="cursor: pointer" data-toggle="modal">
                            <div class="photo-section"></div>
                            <img src="${secondSrc}">
                            <input name="photo-second" type="hidden" value="${secondSrc}" />
                            <textarea style="display:none"></textarea>
                        </div>
                    </div>    
                </div>`;
    }

    /**
     * @param text
     * @param src
     * @returns {string}
     */
    addSectionTextAndPhoto(text, src) {
        text = text ? text : this.texts.addText;
        src = src ? src : this.images.default;

        let filledClass = '';

        if (src !== this.images.default) {
            filledClass = 'filled';
        }

        return `
                <div class="auction-section auction-photo_and_text clearfix" data-type="text-and-photo">
                    ${this.section_bar}
                    <div class="row">
                        <div class="col-lg-6" style="cursor: pointer">
                            <div class="auction-preview">${text}</div>
                            <textarea data-tinymce-system-variables="true" name="text" style="width:100%; height:150px" class="tinymce">${text}</textarea>
                        </div>
                        <div class="col-lg-6 photo-section-wrapper ${filledClass}" style="cursor: pointer" data-toggle="modal">
                            <div class="photo-section"></div>
                            <img src="${src}">
                            <input name="photo" type="hidden" value="${src}" />
                        </div>
                    </div>
                </div>`;
    }

    /**
     * @param text
     * @param src
     * @returns {string}
     */
    addSectionPhotoAndText(text, src) {
        text = text ? text : this.texts.addText;
        src = src ? src : this.images.default;

        let filledClass = '';

        if (src !== this.images.default) {
            filledClass = 'filled';
        }

        return `
                <div class="auction-section auction-photo_and_text clearfix" data-type="photo-and-text">
                    ${this.section_bar}
                    <div class="row">
                        <div class="col-lg-6 photo-section-wrapper ${filledClass}" style="cursor: pointer" data-toggle="modal">
                            <div class="photo-section"></div>
                            <img src="${src}">
                            <input name="photo" type="hidden" value="${src}" />
                        </div>
                        <div class="col-lg-6" style="cursor: pointer">
                            <div class="auction-preview">${text}</div>
                            <textarea data-tinymce-system-variables="true" name="text" style="width:100%; height:150px" class="tinymce">${text}</textarea>
                        </div>
                    </div>
                </div>`;
    }

    /**
     *
     */
    destroy() {
        this.removeTinymce();
        $('#' + this.instance).remove();
        this.target.show();
        this.status = 'ready';
        this.destroyFn(this.target);
    }

    /**
     * @returns {string|boolean}
     */
    joinDescription() {
        if (this.status === 'ready') {
            return false;
        }

        tinymce.EditorManager.triggerSave();

        let description = '';

        let that = this;

        $('#' + this.instance + ' li .auction-section').each(function () {
            let photoUrl = $(this).find('[name="photo"]').val();
            let text = $(this).find('[name="text"]').val();
            let photoUrlFirst = $(this).find('[name="photo-first"]').val();
            let photoUrlSecond = $(this).find('[name="photo-second"]').val();

            switch ($(this).data('type')) {
                case 'text':
                    text = text ? text : ' ';
                    description += `[iai:allegro_description_section_text-begin]${text}[iai:allegro_description_section_text-end]\n`;

                    break;
                case 'photo':
                    description += `[iai:allegro_description_section_photo_list-begin][iai:allegro_description_section_photo_list-end]\n`;

                    break;
                case 'photo-and-text':
                    text = text ? text : '';

                    description += `[iai:allegro_description_section_photo_and_text-begin]`;

                    // if (photoUrl !== that.images.default) {
                    //     description += `[iai:photo_url(${photoUrl})]`;
                    // }

                    description += `${text}[iai:allegro_description_section_photo_and_text-end]\n`;

                    break;
                case 'text-and-photo':
                    text = text ? text : '';

                    description += `[iai:allegro_description_section_text_and_photo-begin]${text}`;

                    // if (photoUrl !== that.images.default) {
                    //     description += `[iai:photo_url(${photoUrl})]`;
                    // }

                    description += `[iai:allegro_description_section_text_and_photo-end]\n`;

                    break;
                case 'double-photo':

                    if (photoUrlFirst === that.images.default && photoUrlSecond === that.images.default) {
                        description += `[iai:allegro_description_section_double_photo] \n`;
                    } else {
                        description += `[iai:allegro_description_section_photo_list-begin][iai:allegro_description_section_photo_list-end]\n`;
                    }

                    break;
            }

        });

        return description;
    }

    /**
     * @returns {boolean}
     */
    save() {
        if (this.status === 'ready') {
            return false;
        }

        this.target.text(this.joinDescription());
    }

    /**
     * @returns {string}
     */
    loadSections() {
        let sectionData = this.explodeDescription();

        // if (sectionData.length === 0) {
        //     return `<li class='item-editor'>${this.addSectionText()}</li>`;
        // }
        let sectionHTML = '';
        for (const key in sectionData) {
            if (sectionData.hasOwnProperty(key)) {
                const section = sectionData[key];

                if (section.type === 'text') {
                    sectionHTML += `<li class='item-editor'>${this.addSectionText(section.text)}</li>`;
                } else if (section.type === 'photo') {
                    sectionHTML += `<li class='item-editor'>${this.addSectionPhoto(section.src)}</li>`;
                } else if (section.type === 'photo-and-text') {
                    sectionHTML += `<li class='item-editor'>${this.addSectionPhotoAndText(section.text, section.src)}</li>`;
                } else if (section.type === 'text-and-photo') {
                    sectionHTML += `<li class='item-editor'>${this.addSectionTextAndPhoto(section.text, section.src)}</li>`;
                } else if (section.type === 'double-photo') {
                    sectionHTML += `<li class='item-editor'>${this.addSectionDoublePhoto(section.firstSrc, section.secondSrc)}</li>`;
                }

            }
        }

        return sectionHTML;
    }

    /**
     *
     */
    addLayout() {
        this.layout = `
                        <div data-tinymce-system-variables="true" class='auction-editor' id='${this.instance}'>    
                            <ul style='width:100%; list-style-type:none; margin:0; padding:0; cursor:N-resize;' class='list-group ui-sortable'>
                                ${this.loadSections()}
                            </ul>
                            <button type='button' class='btn btn-link add-new-section'><i class="far fa-object-ungroup"></i><span>${this.texts.addSection}</span></button>
                        </div>`;
        this.target.after(this.layout);
    }

    /**
     *
     */
    addPhotoModal() {
        if (document.getElementById('modal') == null) {
            $('body').append(`<div class="allegro-photo-modal modal fade" id="modal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        ${this.texts.choosePhoto}
                        <button type="button" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div data-photos-section class="allegro-photos-wrapper">
                        
                        </div>
                    </div>
                </div>
            </div> 
        </div>
`);
            if (typeof this.target.attr('data-mode') !== 'undefined') {
                let $photosSection = $('.allegro-photo-modal').find('[data-photos-section]');

                let LoaderObject = new Loader();
                let loaderSpinner = LoaderObject.createLoader($photosSection.parent());

                let id = +this.target.attr('data-id');

                let route = null;

                if (this.target.attr('data-mode') === 'model') {
                    route = 'model_descriptions_photos';
                }

                $.when(
                    $.ajax({
                        url: Routing.generate(route, {model: id}, false),
                        type: 'POST',
                        dataType: 'json',
                    })
                ).then((results) => {
                    LoaderObject.removeLoader(loaderSpinner);
                    let html = '';

                    for (let result in results) {
                        if (results[result] !== null) {
                            html += '<div class="photo">';
                            html += '<a data-photo-handler href="' + results[result] + '"><img src=' + results[result] + ' class="photo-image"></a>';
                            html += '</div>';
                        }
                    }

                    $photosSection.html(html);

                    $(document).on('click', '[data-photo-handler]', (event) => {
                        event.preventDefault();

                        let src = $(event.currentTarget).attr('href');

                        if (this.activePhoto) {
                            this.activePhoto.find('img').attr('src', src);
                            this.activePhoto.find('input').val(src);
                            this.activePhoto.find('img').show();
                            this.activePhoto.find('.photo-section').hide();

                        }

                        $('.allegro-photo-modal').modal('hide');

                        this.save();
                    })
                });
            }
        }
    }

    /**
     *
     */
    removeTinymce() {
        tinymce.EditorManager.remove('#' + this.instance + ' li .tinymce');

        $('#' + this.instance + ' li .tinymce').each(function () {
            $(this).prev('.auction-preview').html($(this).val());
        });

        this.resetConfigTinymce();
    }

    /**
     *
     */
    initTinymce() {
        tinymce.init(this.tinymceConfig);
        this.resetConfigTinymce();
    }

    /**
     *
     */
    resetConfigTinymce() {
        // if (window['defaultTinyMCESettings']) {
        //     tinymce.init();
        // }
    }

    /**
     * @returns {[]}
     */
    explodeDescription() {
        let regex = /((\[iai:allegro_description_section_(photo_and_text|text_and_photo|text|photo_list)-begin\])(.+?)(\[iai:allegro_description_section_(photo_and_text|text_and_photo|text|photo_list)-end\]))|(\[iai:allegro_description_section_photo\])|(\[iai:allegro_description_section_double_photo\])/mg;
        let str = this.target.val().replace(/(\r\n|\n|\r)/gm, '');
        let sectionValue;
        let sectionData = [];
        let i = 0;

        while ((sectionValue = regex.exec(str)) !== null) {
            if (sectionValue.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            let type = (sectionValue[3] ? sectionValue[3] : sectionValue[0]);
            let content = $.trim(sectionValue[4]);

            if (type === 'text') {
                sectionData[i++] = {
                    'type': 'text',
                    'text': content,
                }
            } else if (type === 'photo_and_text') {
                let regex_photo_and_text = /(\[iai:photo_url\()(.+?)(\)\])(.*)/mg;
                let regex_results = regex_photo_and_text.exec(content);

                if (regex_results) {
                    sectionData[i++] = {
                        'type': 'photo-and-text',
                        'src': (regex_results[2] ? regex_results[2] : ''),
                        'text': (regex_results[4] ? regex_results[4] : ''),
                    }
                } else {
                    sectionData[i++] = {
                        'type': 'photo-and-text',
                        'src': '',
                        'text': content,
                    }
                }
            } else if (type === 'text_and_photo') {
                var regex_text_and_photo = /(.*)(\[iai:photo_url\()(.+?)(\)\])/mg;
                var regex_results = regex_text_and_photo.exec(content);
                if (regex_results) {
                    sectionData[i++] = {
                        'type': 'text-and-photo',
                        'src': (regex_results[3] ? regex_results[3] : ''),
                        'text': (regex_results[1] ? regex_results[1] : ''),
                    }
                } else {
                    sectionData[i++] = {
                        'type': 'text-and-photo',
                        'src': '',
                        'text': content,
                    }
                }
            } else if (type === 'photo_list') {
                let regex_photo_list = /(\[iai:photo_url\()(.+?)(\)])/mg;
                let photo_list = [];

                while ((regex_results = regex_photo_list.exec(content)) !== null) {
                    if (sectionValue.index === regex_photo_list.lastIndex) {
                        regex_photo_list.lastIndex++;
                    }
                    photo_list.push(regex_results[2] ? regex_results[2] : '');
                }

                if (photo_list.length === 1) {
                    sectionData[i++] = {
                        'type': 'photo',
                        'src': photo_list[0],
                    }
                } else if (photo_list.length > 1) {
                    sectionData[i++] = {
                        'type': 'double-photo',
                        'firstSrc': photo_list[0],
                        'secondSrc': photo_list[1],
                    }
                }
            } else if (type === '[iai:allegro_description_section_double_photo]') {
                sectionData[i++] = {
                    'type': 'double-photo',
                    'src': '',
                    'src2': '',
                }
            } else if (type === '[iai:allegro_description_section_photo]') {
                sectionData[i++] = {
                    'type': 'photo',
                    'src': '',
                }
            }
        }
        return sectionData;
    }

    /**
     *
     */
    events() {
        $('#' + this.instance).on('click', 'li.item-editor', (event) => {

            if ($(event.currentTarget).is('.active')) {
                return false;
            }

            this.save();

            this.removeTinymce();

            $('#' + this.instance + ' li.item-editor.active').removeClass('active');
            $(event.currentTarget).addClass('active');

            tinymce.init(this.tinymceConfig);
            this.resetConfigTinymce();

        }).on('click', 'li.item-editor.active [data-toggle="modal"]', (event) => {
            this.activePhoto = $(event.currentTarget);
            $('#modal').modal('show').css('display', 'block');
        }).on('click', '.section-bar a', (event) => {
            event.preventDefault();

            let trigger = $(event.currentTarget).attr('href');
            let triggersList = ['#text', '#photo', '#photo-and-text', '#text-and-photo', '#double-photo', '#delete'];

            if (triggersList.indexOf(trigger) !== -1) {
                event.stopPropagation();

                this.removeTinymce();

                let activeSection = $('#' + this.instance + ' li.active');
                let text = activeSection.find('[name="text"]').text();
                let src = activeSection.find('[name="photo"]').val();
                let firstSrc = activeSection.find('[name="photo-first"]').val();
                let secondSrc = activeSection.find('[name="photo-second"]').val();

                activeSection.html('');

                switch (trigger) {
                    case '#text':
                        activeSection.append(this.addSectionText(text));
                        tinymce.init(this.tinymceConfig);
                        this.resetConfigTinymce();
                        break;
                    case '#photo':
                        activeSection.append(this.addSectionPhoto(src));
                        break;
                    case '#photo-and-text':
                        activeSection.append(this.addSectionPhotoAndText(text, src));
                        tinymce.init(this.tinymceConfig);
                        this.resetConfigTinymce();
                        break;
                    case '#text-and-photo':
                        activeSection.append(this.addSectionTextAndPhoto(text, src));
                        tinymce.init(this.tinymceConfig);
                        this.resetConfigTinymce();
                        break;
                    case '#double-photo':
                        activeSection.append(this.addSectionDoublePhoto(firstSrc, secondSrc));
                        break;
                    case '#delete':
                        this.removeTinymce();
                        activeSection.remove();
                        break;
                    default:
                }
            }
            this.save();
        }).on('click', '.add-new-section', () => {
            $('#' + this.instance + ' > ul').append(`<li class='item-editor'>${this.addSectionText()}</li>`);
            $('#' + this.instance + ' > ul li.item-editor:last').click();
            this.save();
        }).closest('form').on('submit', () => {
            this.save();
        });

        $(document).on('previewInitialize', () => {
            this.save();
        });
    }
}